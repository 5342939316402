import { Form } from '@epages/react-components'
import { createElement, useEffect, useRef, useState } from 'react'
import Immutable from 'immutable'
import cc from 'classcat'
import loadable from '@loadable/component'

import ImageField, { withImageData, withMergedImageData } from './ImageField'
import { ImageEditor } from '../../../../ImageEditor'
import { useEditorSpotlight } from '../../../../EditorSpotlight'
import CtaTextOverlay from '../components/CtaTextOverlay'
import LazyImage from '../../../../LazyImage'
import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import useHelpCenterLink from '../../../../../utils/hooks/useHelpCenterLink'
import useUpdateSettingsLayerPosition from '../../../../../utils/hooks/useUpdateSettingsLayerPosition'
import withI18n from '../../../../withI18n'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const Settings = loadable(() => import(/* webpackChunkName: "editor" */ './ImageSettings'))

const defaultData = Immutable.fromJS({
  src: '',
  width: null,
  height: null,
  alt: '',
  link: '',
  opentab: false,
  text: '',
  buttontext: '',
  headline: '',
  buttonenabled: false,

  // Reference to the original image (image before any modifications).
  // Initially, after image upload, this is always `undefined`. The property is
  // first set on first image editor "init" event. Before that, the "src"
  // property has the reference to the original image.
  // See `ImageEditor` use below.
  refSrc: '',

  // Image editing settings (used with the image editor).
  edit: {
    aspectRatio: { label: undefined, value: undefined },
    offset: [0, 0], // [x, y]
    zoom: 1,
  },
})

export type ImagePluginProps = WorkspacePluginProps & TranslateProps

function ImagePlugin({
  config,
  editorView,
  editorMode,
  editAction,
  isMultiColumn,
  data = defaultData,
  onDataChange,
  onSave,
  onCancel,
  onEdit,
  t,
}: ImagePluginProps) {
  const [isSettingActive, setIsSettingActive] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [imageEditPreviewSrc, setImageEditPreviewSrc] = useState<string>()
  const ref = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const updateSettingsLayerPosition = useRef<VoidFunction>()
  const helpCenterUrl = useHelpCenterLink('IMAGE_FORMATS')

  const isUnsupportedMediaType = error?.code === 415
  const isEmpty = !data.get('src')
  const isLinked = Boolean(data.get('link'))
  const pluginActiveClasses = cc([
    'dali-plugin-image',
    {
      'dali-grid-element-highlighted': isSettingActive,
    },
  ])

  useUpdateSettingsLayerPosition(updateSettingsLayerPosition)

  const handlePluginActiveStateChange = (isActive: boolean) => {
    setIsSettingActive(isActive)
  }

  const handleImageLoaded = () => {
    if (editorMode === 'edit') {
      updateSettingsLayerPosition.current?.()
    }
  }

  // Optimistically put the image placeholder element into spotlight while the
  // initial image is uploading and before the image editor is rendering at the
  // same position in spotlight. Depending on the internet connection, the
  // initial image upload can take a while and we don’t want the user to be
  // able to interact with other elements in the meantime.
  const [spotlightElement, setSpotlightElement] = useState<HTMLElement | null>(null)
  const imagePlaceholderElementRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!editorView) return
    if (isEmpty && imageEditPreviewSrc && !error) {
      setSpotlightElement(imagePlaceholderElementRef.current)
    } else {
      setSpotlightElement(null)
    }
  }, [editorView, isEmpty, imageEditPreviewSrc, error])
  useEditorSpotlight(spotlightElement)

  const emptyImage = (
    <div className="dali-grid-element-placeholder" ref={imagePlaceholderElementRef}>
      <div className="dali-settingslayer-element">
        {error ? (
          <div className="dali-notification-danger">
            {error.message}{' '}
            {isUnsupportedMediaType && (
              <a
                href={helpCenterUrl}
                target="_blank"
                className="ep-form-row-text-external-link"
                rel="noreferrer noopener"
              >
                {t('components.imageUploadComponent.imageField.errorMessages.helpCenterLink')}
              </a>
            )}
          </div>
        ) : null}
      </div>
      <Form
        name="pluginImagePlaceholder"
        value={withImageData(data)}
        prepare={withImageData}
        onChange={(data: ImmutableMap) => {
          onSave(withMergedImageData(data).remove('imageData'))
          onEdit('editImage')
        }}
        className="dali-form"
      >
        <ImageField
          withImageInfo
          name="imageData"
          onError={(error: Error) => setError(error)}
          onChange={() => setError(null)}
          onUploadPreview={(previewSrc: string) => {
            setError(null)
            setImageEditPreviewSrc(previewSrc)
          }}
          storeFile={config?.storeFile}
          editAspectRatio={data.getIn(['edit', 'aspectRatio', 'value'])}
        />
      </Form>
    </div>
  )

  /**
   * Renders the image in the storefront or in the editor. Renders in image
   * editor mode when the edit action is "editImage".
   *
   * The image editor uses the original image as the source for editing:
   *
   * - Initially, the "src" property is always set to the original image.
   * - After the first image editor "init" event, the "refSrc" property is set
   *   to the original image and the "src" property is set to the edited image.
   */
  function renderImage() {
    const aspectRatioMap = isMultiColumn ? multiColumnAspectRatioMap : singleColumnAspectRatioMap
    const image = (
      <>
        <div
          style={{
            display: editAction === 'editImage' ? 'none' : 'block',
            clipPath: data.getIn(['edit', 'aspectRatio', 'label']) === 'circle' ? 'circle(50% at center)' : '',
          }}
          className="dali-image"
        >
          {editorView && imageEditPreviewSrc ? (
            <img
              src={imageEditPreviewSrc}
              alt={data.get('alt')}
              onLoad={handleImageLoaded}
              style={{ width: '100%', aspectRatio: data.getIn(['edit', 'aspectRatio', 'value']), objectFit: 'cover' }}
            />
          ) : (
            <LazyImage
              src={config?.imageUrl ? config.imageUrl(data.get('src')) : data.get('src')}
              width={data.get('width')}
              height={data.get('height')}
              alt={data.get('alt')}
              onLoad={handleImageLoaded}
              innerRef={imageRef}
            />
          )}
        </div>
        {editAction !== 'editImage' && (
          <CtaTextOverlay
            textSettings={{
              text: data.get('text'),
              headline: data.get('headline'),
              buttontext: data.get('buttontext'),
              buttonenabled: data.get('buttonenabled'),
            }}
            image={data}
            isCircle={data.getIn(['edit', 'aspectRatio', 'label']) === 'circle'}
          />
        )}
        {editAction === 'editImage' && (
          <ImageEditor
            src={
              config?.imageUrl
                ? config.imageUrl(data.get('refSrc') || data.get('src'))
                : data.get('refSrc') || data.get('src')
            }
            previewSrc={imageEditPreviewSrc || imageRef.current?.currentSrc}
            aspectRatioMap={aspectRatioMap}
            initial={!data.get('refSrc')}
            edit={
              data.get('edit')?.toJS() ||
              // This fallback is needed for images that were created before
              // the image editor feature was introduced. To convert to the
              // image editor, we initialize with the aspect ratio of the
              // image so there’s no visual change.
              defaultData
                .get('edit')
                .set('aspectRatio', {
                  label: 'original',
                  // We can only set the aspect ratio if we know the width and height of the
                  // image. Older user-uploaded images don’t have this information.
                  value: data.get('width') && data.get('height') ? data.get('width') / data.get('height') : undefined,
                })
                .toJS()
            }
            onInit={async (edit, imageFile) => {
              // Absence of "refSrc" indicates that the image is initially
              // auto-edited with the image editor. To save the auto-edited
              // image whilst keeping the original image, we save the edited
              // image and set the current value of "src" to "refSrc".
              if (!data.get('refSrc')) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const imageUploadResponse = await config!.storeFile!(imageFile)
                setImageEditPreviewSrc(await getDataURLFromFile(imageFile))
                onSave(
                  data.merge(
                    Immutable.fromJS({
                      edit,
                      src: imageUploadResponse.absoluteDownloadUrl,
                      width: imageUploadResponse.width,
                      height: imageUploadResponse.height,
                      refSrc: data.get('src'),
                    }),
                  ),
                  true,
                )
              }
            }}
            onCancel={onCancel}
            onChange={async (edit, imageFile, newImageSourceFile) => {
              const hasEditChanged =
                !Immutable.is(data.get('edit'), Immutable.fromJS(edit)) || Boolean(newImageSourceFile)

              if (hasEditChanged) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const storeFile = config!.storeFile!
                const imageUploads = [storeFile(imageFile)]
                if (newImageSourceFile) {
                  imageUploads.push(storeFile(newImageSourceFile))
                }
                const [imageUploadResponse, newSourceImageUploadResponse] = await Promise.all(imageUploads)
                setImageEditPreviewSrc(await getDataURLFromFile(imageFile))
                onSave(
                  data.merge(
                    Immutable.fromJS({
                      edit,
                      src: imageUploadResponse.absoluteDownloadUrl,
                      width: imageUploadResponse.width,
                      height: imageUploadResponse.height,
                      refSrc: newSourceImageUploadResponse
                        ? newSourceImageUploadResponse.absoluteDownloadUrl
                        : data.get('refSrc'),
                    }),
                  ),
                )
              } else {
                onCancel()
              }
            }}
          />
        )}
      </>
    )

    if (!editorView && isLinked) {
      const linkTarget = data.get('opentab') ? '_blank' : undefined
      const rel = linkTarget ? 'noopener noreferrer' : undefined

      return (
        <a
          href={data.get('link')}
          target={linkTarget}
          rel={rel}
          style={{
            aspectRatio: editAction === 'editImage' ? 'unset' : data.getIn(['edit', 'aspectRatio', 'value']),
          }}
          className="dali-image-wrapper"
        >
          {image}
        </a>
      )
    }

    return (
      <div
        style={{
          aspectRatio: editAction === 'editImage' ? 'unset' : data.getIn(['edit', 'aspectRatio', 'value']),
        }}
        className="dali-image-wrapper"
      >
        {image}
      </div>
    )
  }

  const renderSettingsLayer = () => {
    return (
      <SettingsLayer
        referenceElement={ref.current}
        placement="right"
        onActiveStateChange={handlePluginActiveStateChange}
        onEscapeKeyDown={onCancel}
      >
        {({ updatePosition, renderLayout }) => {
          updateSettingsLayerPosition.current = updatePosition

          return createElement(Settings, {
            data,
            onDataChange,
            config,
            onCancel,
            renderLayout,
            updateSettingsLayerPosition: updatePosition,
            onSave: (data) => {
              setError(null)
              onSave(data)
            },
          })
        }}
      </SettingsLayer>
    )
  }

  if (editorView) {
    return (
      <div className={pluginActiveClasses} ref={ref}>
        {isEmpty ? emptyImage : renderImage()}
        {editorMode === 'edit' && editAction !== 'editImage' && renderSettingsLayer()}
      </div>
    )
  }

  return !isEmpty ? <div className={pluginActiveClasses}>{renderImage()}</div> : null
}

ImagePlugin.actionBarButtons = { edit: true }

export default compose(withI18n('interface'), translate())(ImagePlugin)

const singleColumnAspectRatioMap = {
  '3:1': 3 / 1,
  '3:2': 3 / 2,
  '4:3': 4 / 3,
  '5:1': 5 / 1,
  '16:9': 16 / 9,
}

const multiColumnAspectRatioMap = {
  '1:1': 1,
  '2:3': 2 / 3,
  '3:1': 3 / 1,
  '3:2': 3 / 2,
  '4:3': 4 / 3,
  '16:9': 16 / 9,
  circle: 1,
}

function getDataURLFromFile(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}
